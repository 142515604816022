@import "../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-pro/scss/thin.scss";
@import "../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-normal leading-relaxed;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-display font-semibold tracking-wide leading-loose;
  }

  h1 {
    @apply text-5xl md:text-6xl mb-6;
  }
  h1.display {
    @apply md:text-8xl;
  }

  h2 {
    @apply text-3xl md:text-4xl mb-6;
  }

  h3 {
    @apply text-xl md:text-2xl mb-3;
  }

  h4 {
    @apply text-lg md:text-xl mb-2;
  }

  /*
  p {
    @apply leading-relaxed
  }
  */

  ul {
    @apply list-disc list-inside;
  }
}